.landing-ot {
    padding-bottom: 50px!important;
}
.landing-wrap {
    min-height: calc(100vh - 170px);
    max-width: 800px;
    width: 100%;
    padding:  0 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.landing-iner-wrap {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: #000000;
    box-shadow: 21px 21px 80px 50px rgba(0, 0, 0, 0.25), -21px -21px 80px 50px rgba(0, 0, 0, 0.25), inset 14px 14px 116px -8px rgba(238, 125, 160, 0.35);   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width:768px) {
        border-radius: 110px;
    }
    @media screen and (max-width:767px) {
        border-radius: 50px;
    }
    h1 {
        color: #FF0087;
        margin-top: 50px;
        font-weight: 400;
        @media screen and (max-width:767px) {
            font-size: 24px;
        }
    }
}