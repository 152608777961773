$font-stack: 'Nasalization Rg';

body {
    font-family: $font-stack;
}

.main-wrap {
    width: 100%;
    background-image: url(../images/banner-img.svg);
    background-size: cover;
    @media screen and (min-width:768px) {
        padding-bottom: 210px;
    }
    @media screen and(max-width:767px) {
        padding-bottom: 70px;
    }
}


.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width:768px) {
        padding: 35px;
    }
    @media screen and(max-width:767px) {
        padding: 35px 15px;
    }
    img {
        @media screen and (min-width:768px) {
            width: 200px;
        }
        @media screen and(max-width:767px) {
            width: 130px;
        }
    }
    button {
        color: #fff;
        font-family: $font-stack;
        border-radius: 40px;
        font-weight: 400;
        border: solid 2px #fff;
        background-color: transparent;
        &:hover {
            color: #FF0087;
            border-color: #FF0087;
        }
        @media screen and (min-width:768px) {
            min-width: 160px;
            font-size: 16px;
            height: 50px;
            padding: 0 20px;
        }
        @media screen and (max-width:767px) {
            min-width: 140px;
            font-size: 12px;
            height: 40px;
            padding: 0 15px;
        }
    }
}

.powered-by-wrap {
    background-color:#000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 60px;
    &>div {
        display: flex;
        flex-direction: column;
    }
    .power-text {
        font-size: 14px;
        font-weight: 400;
        color: #a0a0a0;
        margin-bottom: 10px;
    }
}