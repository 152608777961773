.banner-wrapper {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width:768px) {
        padding-top: 150px;
    }
    @media screen and (max-width:767px) {
        padding-top: 80px;
        margin-bottom: 40px;
    }
    h1 {
        font-weight: 400;
        color: #fff;
        @media screen and (min-width:768px) {
            font-size: 36px;
            margin-bottom: 60px;
        }
        @media screen and (max-width:767px) {
            font-size: 24px;
            margin-bottom: 40px;
        }
    } 
}

.quantity-wrap {
    max-width: 1200px;
    width: 100%;
    background: #000000;
    opacity: 0.9;
    box-shadow: 21px 21px 50px 10px rgba(0, 0, 0, 0.25), -21px -21px 50px 10px rgba(0, 0, 0, 0.25), inset 14px 14px 116px -8px rgba(238, 125, 160, 0.35);
    border-radius: 60px;
    margin: 0 auto;
    @media screen and (min-width:768px) {
        padding: 60px;
    }
    @media screen and (max-width:767px) {
        padding: 40px 15px 20px;
    }
    h2 {
        color:#FF0087;
        font-weight: 400;
        @media screen and (min-width:768px) {
            font-size: 24px;
            margin-bottom: 40px;
        }
        @media screen and (max-width:767px) {
            font-size: 18px;
        }
    }
    h3 {
        color: #fff;
        font-weight: 400;
        @media screen and (min-width:768px) {
            font-size: 20px;
            margin-bottom: 0;
        }
        @media screen and (max-width:767px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
}

.rounds-wrap {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #000000;
    @media screen and (min-width:768px) {
       padding-top: 60px;
       border-radius: 147px 147px 0px 0px;
       margin-top: -140px;
    }
    @media screen and (max-width:767px) {
        padding-top: 40px;
        border-radius: 40px 40px 0px 0px;
       margin-top: -50px;
     }
    h2 {
        text-align: center;
        color:#FF0087;
        font-weight: 400;
        margin-bottom: 90px;
        @media screen and (max-width:767px) {
            font-size: 20px;
         }
    }
    
}

.round-boxes-wrap {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto!important;
    .round-switch {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        text-align: center;
        @media screen and (min-width:768px) {
          right: 210px;
        }
        @media screen and (max-width:767px) {
            right: 0;
            align-items: flex-end;
          }
        span {
            color: #fff;
            margin-bottom: 10px;
        }
        .ant-switch {
            @media screen and (min-width:768px) {
                height: 40px;
                width: 80px;
              }
              @media screen and (max-width:767px) {
                  right: 0;
                  height: 30px;
                  width: 60px;
                }
            background-color: #fff;
            &.ant-switch-checked {
                .ant-switch-handle {
                    @media screen and (min-width:767px) {
                            left: calc(100% - 36px - 2px);
                    }
                    @media screen and (max-width:767px) {
                        left: calc(100% - 26px - 2px);
                    }
                }
            }
            .ant-switch-handle {
                @media screen and (min-width:768px) {
                    width: 36px;
                    height: 35px;
                  }
                  @media screen and (max-width:767px) {
                    width: 25px;
                    height: 25px;
                    }
                &::before {
                    border-radius: 20px;
                    background-color: #000000;
                }
            }
        }
    }
}

.round-inr-box {
    width: 100%;
    background: #000000;
    box-shadow: 0px 0px 24px rgba(255, 0, 135, 0.5);
    border-radius: 16px;
    position: relative;
    @media screen and (min-width:768px) {
      max-width: 450px;
      padding: 50px 40px 10px;
      margin: 0 auto 80px;
    }
    @media screen and (max-width:767px) {
      padding: 50px 15px 10px;
      margin: 0 auto 50px;
    }
    .ant-image {
        position: absolute;
        top:-28px;
        left:-18px;
    }
    .r-head {
        display: flex;
        justify-content: space-between;
    }
    h4 {
        color:#FF0087;
        font-weight: 400;
        @media screen and (min-width:768px) {
            font-size: 24px;
          }
          @media screen and (max-width:767px) {
            font-size: 18px;
          }
    }
    ul {
        padding-left: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin-top:30px;
        margin-bottom: 0;
        li {
            width: 50%;
            color: #fff;
            text-align: center;
            margin-bottom: 25px;
            list-style: none;
            span {
                display: block;
            }
        }
    }
    button {
        border: none;
        height: 42px;
        border-radius: 6px;
        background-color: #fd5354;
        font-size: 16px;
        font-weight: 400;
        @media screen and (min-width:768px) {
            width: 109px!important;
          }
          @media screen and (max-width:767px) {
            width: 85px!important;
          }
    }
    &.strategic-box {
        button {
            color: #c2b6bc;
            background-color: #908089;
        }
    }
}

.footer {
    width: 100%;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #000000;
      @media screen and (max-width:767px) {
        text-align: center;
      }
    span {
        font-size: 15px;
        color: #fff;
        opacity: 0.75;
        font-weight: 400;
        display: block;
        margin-top: 40px;
    }
    .wrap {
        max-width: 1200px;
        margin: 0 auto;
    }
    ul{
        padding-top: 40px;
        list-style: none;
          @media screen and (max-width:767px) {
            padding-left: 0;
          }
        li {
            margin-bottom: 10px;
            a {
                color: #fff;
                opacity: 0.75;
                font-weight: 400;
              
                    &:hover, &:focus {
                        color: #FF0087;
                    }
        
            }
        }
    }
}